.low-button {
  height: 26px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #3d5170;
  background: transparent;
  border-radius: 8px;
  border-color: transparent;
  border-width: 1px;
  justify-content: center;
  align-items: center;
  float: left;
  display: flex;
  padding: 12px;
  opacity: 30%;
}

.low-button-white {
  background: #ffffff;
  opacity: 100%;
  max-width: 200px;
  padding: 0 12px;
  justify-content: left;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
}

.low-button-clicked {
  height: 26px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #3d5170;
  background: #ffffff;
  border-radius: 8px;
  border-color: transparent;
  border-width: 1px;
  justify-content: center;
  align-items: center;
  float: left;
  display: flex;
  padding: 12px;
  opacity: 100%;
}

.low-button-clicked-filter {
  padding: 0 12px;
  justify-content: left;
  overflow-y: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
}

@media only screen and (min-width: 1000px) {
  .low-button {
    background-color: #f7f8fa;
    color: #8493ab;
    margin-right: 35px;
  }

  .low-button-white {
    margin-bottom: 7px;
    margin-right: 5px;
  }

  .low-button-clicked {
    background-color: #11a8b8;
    color: #fff;
  }
}
