.fullscreen-preview {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.fullscreen-image {
  width: 100%;
  height: 100%;
}

.fullscreen-content {
  width: 95%;
  height: 95%;
  background-color: #fff;
  border-radius: 15px;
  max-width: 800px;
}

.fullscreen-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  color: white;
  border: none;
  padding: 5px;
  cursor: pointer;
}
