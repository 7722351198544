.swipeable-container {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.swipeable-card {
  width: 100%;
}

.delete-button {
  top: 0;
  right: -100px;
  width: 41px;
  height: 41px;
  display: inline-block;
  height: 100%;
  background-color: #ffffff;
  border-radius: 50px;
  border-color: transparent;
  transition: right 0.3s ease-in-out;
  width: 45px;
  margin-left: 5px;
}

.delete-button button {
  width: 41px;
  height: 41px;
  background-color: #ffffff;
  outline: none;
  border-color: transparent;
  border-radius: 50px;
  cursor: pointer;
}

.delete-button button img {
  vertical-align: middle;
}
