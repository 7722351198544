.content-wrapper {
  width: 100%;
  margin-bottom: 60px;
}

.content {
  width: 100%;
  padding: 12px;
}

.image {
  margin-bottom: 20px;
  position: relative;
  background-color: #fff;
  border-radius: 15px;
  border-bottom: 1px solid #c4c4c4;
  height: 500px;
}
