.content-insight {
  width: 100%;
}

.tabs {
  width: 100%;
  padding: 12px;
  margin-top: 8px;
}

.buttons {
  display: flex;
  overflow: scroll;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: transparent;
}

.monitors {
  margin-top: 8px;
}
