.add-button {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 28px;
  border-color: transparent;
  margin-top: auto;
  margin-bottom: auto;
  display: grid;
  align-items: center;
  justify-content: center;
}

.add-button--dark {
  background: #11a8b8;
}
