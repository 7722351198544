.create-floor__button {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  height: 43px;
  background-color: #f2f2f2;
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: #3d5170;
  font-family: Roboto;
  line-height: 20.02px;
  letter-spacing: 0.15px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 17px;
}

.services__issue-type {
  background: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #c4c4c4;
}

.slider {
  margin-top: 10px;
  padding: 15px;
}

.services__issue-type__buttons {
  display: flex;
  justify-content: space-between;
  opacity: 0.75;
  padding: 10px;
}

.services__issue-type__button {
  color: #8493ab;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  border-radius: 8px;
  border: 2px #8493ab solid;
  padding: 7px;
  width: 49%;
  text-align: center;
  cursor: pointer;
}

.services__issue-type__button--active {
  background-color: #11a8b8;
  color: #fff;
  border-color: #11a8b8;
}

.services__issue-type__infestation {
  background-color: #fff;
  border-bottom: 1px solid #c4c4c4;
  border-radius: 10px;
  margin-bottom: 12px;
}

.issue-type__infestation-title {
  background-color: #f2f2f2;
  color: #3d5170;
  font-size: 14px;
  padding: 12px;
  font-weight: 500;
}

.issue-type__infestation-item {
  padding: 10px 17px;
  color: #3d5170;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.materials {
  margin-bottom: 20px;
}

.custom-slider {
  margin-top: 35px;
}
