.card {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 95px;
  background: #ffffff;
  border-bottom: 1px solid #c4c4c4;
  border-radius: 10px;
  overflow: hidden;
  padding-right: 50px;
  color: #f29100;
  cursor: pointer;
}

.card__circle {
  display: flex;
  width: 56px;
  min-width: 56px;
  height: 56px;
  justify-content: center;
  align-items: center;
  color: #11a8b8;
  background: rgba(131, 217, 225, 0.3);
  border-radius: 28px;
  margin: 12px;
}

.card__info {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: flex-start;
  text-align: left;
  gap: 4px;
}

.card__info__title {
  font-style: normal;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  /* Use ellipsis for text overflow */
  white-space: nowrap;
  /* Prevents the text from wrapping */
  line-height: 24px;
  font-size: 16px;
  color: #3d5170;
}

.card__info__title i {
  margin-right: 5px;
  font-weight: 400;
}

.card__info__subtitle {
  color: #8493ab;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(100% - 36px);
  font-size: 13px;
  font-weight: 500;
}

.card__type {
  background: rgba(237, 239, 245, 0.8);
  border-radius: 25px;
  font-size: 12px;
  padding: 2px 10px;
}

.card__tags {
  display: flex;
  align-items: center;
}

.card__type--responsibility {
  background: rgba(237, 239, 245, 0.8);
  border-radius: 25px;
  font-size: 12px;
  padding: 2px 10px;
  color: #11a8b8;
  width: fit-content;
  margin-right: 5px;
}

@media only screen and (min-width: 1000px) {
  .card {
    padding-right: 10px;
  }

  .card__info {
    flex-direction: row;
    align-items: center;
  }

  .card__info__title {
    overflow: visible;
    margin-right: 20px;
  }

  .card__type {
    padding: 3px 20px;
  }

  .card__type--responsibility {
    padding: 3px 20px;
  }
}
