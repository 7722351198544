@media only screen and (min-width: 1000px) {
  .piechart {
    background-color: #f7f8fa;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .piechart__container {
    display: flex;
    padding-top: 65px;
    padding-bottom: 35px;
    padding-right: 40px;
  }

  .piechart__title {
    display: flex;
    align-items: center;
    color: #3d5170;
    margin-left: 12px;
    gap: 10px;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .piechart__subtitle {
    max-width: 350px;
    display: flex;
    align-items: center;
    color: #3d5170;
    margin-left: 12px;
    gap: 10px;
    font-size: 16px;
    margin-bottom: 40px;
  }

  .piechart__description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 350px;
    margin-left: 10%;
  }

  .pie-chart-container {
    display: flex;
  }

  .legend-container {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    width: fit-content;
    display: flex;
    justify-content: center;
  }

  .legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .legend-color {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }

  .legend-label {
    font-size: 14px;
    color: #3d5170;
    text-transform: capitalize;
  }

  .thickline {
    border: 3px solid #ced3dc;
    border-radius: 4px;
    width: 90%;
    margin: auto;
  }
}
