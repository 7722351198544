.content-wrapper {
  width: 100%;
  margin-bottom: 60px;
}

.content {
  width: 100%;
  padding: 12px;
}

.image {
  margin-bottom: 20px;
  position: relative;
  background-color: #fff;
  border-radius: 15px;
  border-bottom: 1px solid #c4c4c4;
  height: 500px;
}

.date-time-picker h4 {
  padding: 10px 0px 5px 0px;
  font-weight: 500;
  font-size: 14px;
  color: #3d5170;
}

.space-under {
  margin-bottom: 14px;
}

@media only screen and (min-width: 1000px) {
  .content-wrapper {
    background-color: #eceef3;
    padding: 36px 45px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom: 1px #c4c4c4 solid;
  }
}
