.services__info-box {
  width: 100%;
  background-color: #f3c2c2;
  border-radius: 10px;
  border-bottom: 1px #f10000 solid;
  padding: 10px 10px 10px 17px;
  display: flex;
  justify-content: space-between;
  color: #069aa9;
  margin-bottom: 13px;
}

.services__info-box--text {
  color: #3d5170;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  display: inline-block;
  word-break: break-word;
  hyphens: auto;
}

.services__info-box--column {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.services__info-box--tag {
  color: #cb3844;
  font-size: 12px;
  letter-spacing: 0.3px;
  background-color: #fff;
  padding: 3px 6px;
  border-radius: 25px;
  line-height: 14.4px;
  text-align: center;
  width: fit-content;
}

.box-tag-green {
  color: #0eaa00;
}

.box-tag-red {
  color: #cb3844;
}

.box-tag-white {
  color: #069aa9;
  background-color: #f1f2f7;
}

.box-tag-blue {
  color: #15a8fb;
  background-color: #f1f2f7;
}

.info-box-red {
  background-color: #f3c2c2;
  border-color: #f10000;
}

.info-box-green {
  background-color: #a0e29a;
  border-color: #ced3dc;
}

.info-box-white {
  background-color: #ffffff;
  border-color: #ced3dc;
}

.info-box-blue {
  background-color: #a5ddfd;
  border-color: #ced3dc;
}

.services__info-box--tags {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;
}

.date {
  background: #d0fafe;
  color: #069aa9;
  width: 50px;
  border-radius: 25px;
  font-size: 12px;
  padding: 3px 8px;
  display: flex;
  justify-content: center;
}

.card__info__type {
  color: #069aa9;
  background: rgba(237, 239, 245, 0.8);
  border-radius: 25px;
  font-size: 12px;
  padding: 3px 6px;
}
