.wrapper {
  margin-top: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.wrapper > h1 {
  font-size: 20px;
  font-weight: 700;
  color: #3d5170;
  margin-top: 20px;
}

.wrapper > p {
  margin-top: 20px;
  width: 70%;
  color: #8493ab;
  font-size: 15px;
  font-weight: 500;
  line-height: 25px;
  text-align: center;
}
