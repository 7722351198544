.content {
  width: 100%;
  height: calc(100% - 76px - 50px);
  background: #edeff5;
  padding: 12px;
}

.content__text-card {
  width: 100%;
  max-width: 500px;
  padding: 12px;
  background: #ffffff;
  border-radius: 10px;
  color: #3d5170;
  margin-top: 10px;
  border: 1px solid #ced3dc;
}

.content__text-card p {
  text-align: left;
  font-style: italic;
}
