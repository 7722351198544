.wrapper {
  position: fixed;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 85px;
  display: flex;
  justify-content: space-evenly;
  max-width: 90%;
  z-index: 999;
}

.justify-right {
  justify-content: right;
}

@media only screen and (min-width: 1000px) {
  .wrapper {
    position: sticky;
    bottom: -54px;
  }
}
