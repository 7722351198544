.materials-list {
  border-bottom: 1px solid #c4c4c4;
  background: #ffffff;
  border-radius: 10px;
}

.materials-list__title {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #3d5170;
  padding: 12px 0 0 17px;
  background-color: #f7f8fa;
  height: 43px;
  border-radius: 10px;
}
