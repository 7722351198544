.card {
  width: 100%;
  max-width: 500px;
  height: 72px;
  padding: 12px 16px;
  background: #02bd4d;
  border-radius: 8px;
  color: #ffffff;
  margin-top: 10px;
  border: 1px solid #ced3dc;
  display: flex;
  justify-content: space-between;
}

.card--declined {
  background: #f27171;
  color: #ffffff;
}

h4 {
  font-size: 14px;
  font-weight: 400;
}

h3 {
  font-size: 16px;
  margin-top: 6px;
  font-weight: 600;
}
