.task-details__card {
  border-bottom: 1px solid #11a8b8;
  display: flex;
  width: 100%;
  height: 95px;
  padding: 12px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: transparent;
  box-shadow: 0px 4px 4px 0px #0000000d;
  position: relative;
  align-items: center;
}

.task-details__card--white {
  background: #ffffff;
}

.task-details__card--green {
  background: #d0fafe;
}

.task-details__service-summary {
  color: #3d5170;
  position: absolute;
  top: -10px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.04px;
}

.task-details__circle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 4px;
  border-color: transparent;
  min-width: 56px;
  height: 56px;
  background-color: #83d9e1;
  color: #11a8b8;
  border-radius: 28px;
  margin: auto;
}

.task-details__task {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  height: fit-content;
  flex-grow: 1;
  gap: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: center;
  width: min-content;
}

.task-details__titles {
  width: fit-content;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 24px;
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #3d5170;
}

.task-details__infos {
  min-width: 130px;
  height: 24px;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #8493ab;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.task-details__button {
  background-color: #edeff5;
  width: fit-content;
  height: 20px;
  border-radius: 25px;
  border-color: transparent;
  color: #069aa9;
  font-style: normal;
  padding: 0px 6px 3px 6px;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.3px;
  text-transform: capitalize;
}

.task-details__button--orange {
  color: #f29100;
}

@media only screen and (min-width: 1000px) {
  .task-details__task {
    flex-direction: row;
    align-items: center;
    justify-content: start;
  }

  .task-details__titles {
    margin-top: 0;
  }

  .task-details__button {
    padding: 0 20px 3px 20px;
  }

  .task-details__infos {
    margin: 0 40px;
  }

  .task-details__service-summary {
    top: -38px;
    left: 93px;
  }

  .task-details__card {
    padding: 0 35px 25px 35px;
    box-shadow: none;
    border-bottom: 1px #c4c4c4 solid;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
