.content-wrapper {
  padding-right: 14px;
  padding-left: 14px;
  padding-top: 18px;
}

.monitors {
  margin-top: 8px;
}

.title {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  color: #3d5170;
}

.materials {
  margin-top: 20px;
}

@media only screen and (min-width: 1000px) {
  .content-wrapper {
    background-color: #eceef3;
    padding: 36px 45px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom: 1px #c4c4c4 solid;
  }
}
