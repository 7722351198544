.content-wrapper {
  width: 100%;
}

.content {
  width: 100%;
  padding: 12px;
  margin-top: 8px;
  color: #3d5170;
}

.monitor-adjust--title {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
}

.monitor-adjust--title > img {
  margin-right: 7px;
}

.monitor-adjust--map {
  height: 500px;
  position: relative;
  background-color: #fff;
  border-radius: 15px;
  border-bottom: 1px solid #c4c4c4;
  margin: 15px;
}

.monitor-adjust-data--map {
  height: 300px;
}
