.app-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.app-layout__body {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.app-layout__content {
  width: 100%;
  background: rgba(237, 239, 245, 0.8);
  overflow-y: scroll;
  flex: 1 1;
  max-width: 700px;
  margin: 0 auto;
  padding-bottom: 80px !important;
}

@media only screen and (min-width: 1000px) {
  .app-layout__body {
    flex-direction: row-reverse;
    background: rgba(237, 239, 245, 0.8);
  }

  .app-layout__content {
    max-width: calc(100% - 300px);
    padding: 64px;
    position: relative;
    background-color: transparent;
  }
}

@media only screen and (min-width: 1600px) {
  .app-layout__content {
    max-width: 1300px;
  }
}
