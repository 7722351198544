.container {
  width: 100%;
  height: 100%;
  padding-top: 40px;
  position: relative;
  background-color: #fff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.settings__heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  padding-left: 30px;
}

.settings__title {
  display: flex;
  align-items: center;
  color: #3d5170;
  margin-left: 12px;
  gap: 10px;
  font-size: 18px;
  font-weight: 500;
}

.settings-content {
  background-color: #f7f8fa;
  width: 100%;
  flex: 1 1;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
}

.settings-bottom {
  margin-bottom: 30px;
}

.settings-bottom__feedback-link {
  background-color: #fff;
  height: 48px;
  display: flex;
  align-items: center;
  color: #3d5170;
  font-weight: 500;
  font-size: 16px;
  border-radius: 10px;
  margin: 16px 24px;
  padding-left: 28px;
  cursor: pointer;
  user-select: none;
}

.settings-bottom__feedback-link > img {
  margin-right: 4px;
}

.version {
  position: absolute;
  bottom: 0;
  left: 0;
  left: 50%;
  transform: translateX(-50%);
}

.list__card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px 12px 0px;
  margin: 0 17px;
  border: 1px solid #c4c4c4;
  cursor: pointer;
}

.item {
  font-size: 16px;
  color: #3d5170;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
}

.flex {
  display: flex;
  justify-content: space-between;
  padding-right: 28px;
}