.no-connection-modal {
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 9999;
  right: 0;
  position: absolute;
  background-color: #ffffff;
  opacity: 0.9;
}

@media (max-width: 390px) {
  .content {
    margin-top: 50%;
  }
}

@media (min-width: 390px) {
  .content {
    margin-top: 336px;
  }
}

.checkbox {
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  height: 40px;
}

.text {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.03px;
  color: #3d5170;
  width: 184px;
  margin-right: auto;
  margin-left: auto;
}

.checkbox__text {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: #3d5170;
  width: 172px;
  float: right;
}

input {
  width: 24px;
  height: 36px;
  accent-color: #1898a9;
}

.warning-button {
  background: #f29100;
  color: white;
  min-width: 150px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 15px;
  padding: 16px 18px;
  border: none;
  border-radius: 30px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 30px;
}
