.comments {
  width: 100%;
  min-height: 110px;
  border: none;
  background-color: #ffffff;
  color: #3d5170;
  border-bottom: 1px solid #ced3dc;
  border-radius: 10px;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
}

.comments__header {
  display: flex;
  align-items: center;
  height: 48px;
  justify-content: space-between;
  width: 100%;
}

.add-comment {
  width: 100%;
  position: relative;
}

.add-comment textarea {
  width: 100%;
}

.comments__list {
  max-height: 340px;
  overflow-y: auto;
  padding-right: 8px;
  box-sizing: border-box;
  scrollbar-color: #11a8b8 #edeff5;
}

.add-comment__button {
  position: absolute;
  bottom: 10%;
  right: 10px;
  transform: translateY(-50%);
  width: 134px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background-color: #8493ab;
  color: #fff;
  border: none;
  flex-shrink: 0;
}

.add-comment__button.filled {
  background-color: #11a8b8;
}

.comment {
  margin-bottom: 24px;
}

.comment__by {
  display: flex;
  gap: 4px;
  margin-bottom: 4px;
}

.comment__author {
  font-size: 14px;
  font-weight: 500;
}

.comment__content {
  font-weight: 400;
  font-size: 14px;
  color: #3d5170;
}

.processed-info {
  color: #11a8b8;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 24px;
}

.no-comments {
  font-weight: 400;
  font-size: 14px;
  font-style: italic;
  color: #8493ab;
}
