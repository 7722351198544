:root {
  --orange: #f29100;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80px;
  background-color: white;
}

.header--green {
  background-color: #d0fafe;
  margin: 0;
  color: #3d5170;
}

.header--dropdown {
  background-color: #e3f9fc;
}

.header--minimized {
  height: 52px;
  margin-bottom: 0;
}

.header--minimized button {
  height: 28px;
  width: inherit;
  flex: 0;
}

.header--minimized .header__actions {
  position: relative;
  top: 0;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 10px rgba(61, 81, 112, 0.15);
  border-radius: 12px;
}

.header__welcome-message {
  height: 54px;
  display: flex;
  justify-content: left;
  padding-left: 20px;
  align-self: start;
  align-items: center;
  color: #069aa9;
}

.header__error {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;
  gap: 4px;
  font-style: italic;
  font-size: 1em;
  color: #3d5170;
}

.header__error svg {
  color: black;
}

.header__actions {
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  top: 50px;
  gap: 7px;
  padding: 0 20px;
  height: 56px;
  z-index: 100;
}

.status,
.checkin {
  display: flex;
  align-items: center;
  color: white;
  border-radius: 50px;
  padding: 16px;
  border: none;
  justify-content: center;
}

.checkin {
  background: #11a8b8;
  min-width: fit-content;
}

.checkin svg {
  width: 20px;
  height: 20px;
}

.status {
  background: #3d5170;
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
  font-size: 15px;
  overflow: hidden;
  padding: 16px 18px;
  min-width: 56px;
}

.status--offline {
  background: var(--orange);
  white-space: nowrap;
  gap: 3px;
  min-width: 150px;
}

.status--offline span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.status--offline img {
  transform: scale(0.7);
}

.status:disabled {
  background: rgb(192, 192, 192);
}

.status__icon {
  transform: scale(0.75);
}

.desktop-header {
  /* height: 80px; */
  border-radius: 12px;
  position: relative;
}

/*Customer*/

.customer-header__actions,
.header--minimized .customer-header__actions {
  justify-content: flex-start;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.header__profile-picture {
  width: 48px;
  height: 48px;
  background-color: #069aa9;
  border-radius: 50%;
  margin-right: 22px;
}

.header--minimized .header__profile-picture {
  width: 40px;
  height: 40px;
}

.header__profile-name {
  color: #3d5170;
  font-size: 16px;
  font-weight: 600;
}

.header__profile-property {
  color: #8493ab;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

@media only screen and (min-width: 700px) {
  .header__actions {
    height: 100%;
  }

  .customer-header {
    margin-bottom: 24px;
  }

  .dropdown-item {
    height: 40px;
    background-color: #d0fafe;
    padding-left: 90px;
    padding-top: 10px;
    color: #3d5170;
    cursor: pointer;
  }

  .dropdown-menu {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
  }

  .dropdown-menu div:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .header__dropdown {
    position: absolute;
    right: 10px;
    cursor: pointer;
  }
}
