.card__wrapper {
  background-color: white;
  border-radius: 10px;
  margin-top: 10px;
  color: #3d5170;
  border-bottom: 1px #c4c4c4 solid;
}

.card__wrapper__content {
  font-size: 14px;
  font-weight: 500;
  padding: 13px 20px 13px 20px;
  cursor: pointer;
}

.card__wrapper__content--shadow {
  box-shadow: 0 4px 6px -7px #222;
}

.card__wrapper__content--title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.card__wrapper__content--title-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 10px;
}

.showMap {
  max-width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.card__wrapper__content--title--gray {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card__wrapper__list {
  height: auto;
  width: auto;
  font-size: 14px;
  padding: 0px 20px 0px 20px;
}

.card__wrapper__content--white {
  background: #ffffff;
  border-start-end-radius: 10px;
  border-start-start-radius: 10px;
}

.card__wrapper__content--gray {
  background: #f7f8fa;
  border-start-end-radius: 10px;
  border-start-start-radius: 10px;
}

.card__wrapper__content--title-icons {
  display: flex;
  align-items: center;
  gap: 5px;
}

.image {
  position: relative;
  background-color: #fff;
  border-bottom: 1px solid #c4c4c4;
  height: 400px;
  width: 100%;
}
.image-container {
  width: 100%;
  margin-top: 20px;
  display: flex;
  gap: 20px;
}
