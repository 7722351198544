.tabs {
  width: 100%;
  padding: 12px;
  margin-top: 8px;
}

.buttons {
  display: flex;
  overflow: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media only screen and (min-width: 1000px) {
  .scrollable-wrapper {
    background-color: #fff;
    border-radius: 12px;
    padding: 36px 48px;
    margin-top: 24px;
  }

  .dashboard-title {
    display: flex;
    align-items: center;
    color: #3d5170;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .dashboard-title > svg {
    margin-right: 15px;
  }
}
