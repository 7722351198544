.materials-list__card {
  display: flex;
  height: 52px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 5px;
  align-items: flex-start;
  margin-left: 12px;
  margin-right: 12px;
  border-bottom: 1px solid #ced3dc;
}

.materials-list__card--readonly {
  padding: 10px 5px;
  height: 40px;
  border-bottom: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 280%;
  letter-spacing: 0.15px;
}

.materials-list__card:last-child {
  border-bottom: none;
}

.materials-list__item {
  font-size: 14px;
  font-family: Roboto;
  line-height: 143%;
  letter-spacing: 0.15px;
  text-align: left;
  color: #3d5170;
}

.materials-list__left {
  display: flex;
  gap: 15px;
  align-items: center;
}

.materials-list__quantity {
  display: inline-flex;
  align-items: flex-start;
  gap: 12px;
  font-size: 16px;
  font-family: Roboto;
  line-height: 24px;
  letter-spacing: 0.15px;
  width: 90px;
}

.materials-list__quantity-readOnly {
  display: inline-flex;
  align-items: flex-start;
  gap: 12px;
  font-size: 14px;
  font-family: Roboto;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #3d5170;
  width: 25px;
}

.input__quantity {
  width: 100%;
  height: 24px;
  border-radius: 4px;
  font-size: 16px;
  background: #d0fafe;
  border: 1px solid #069aa9;
  text-align: center;
}

.input__quantity::-webkit-outer-spin-button,
.input__quantity::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.span__quantity {
  width: 30px;
  text-align: center;
  cursor: pointer;
}
