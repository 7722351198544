html {
  overflow: hidden;
}

.pull-refresh-container__indicator {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow-y: hidden;
}

.pull-refresh__indicator__spinner {
  display: inline-block;
  width: 50px;
  height: 50px;
}

.pull-refresh__indicator__spinner:after {
  content: " ";
  display: block;
  width: 32px;
  height: 32px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #11a8b8;
  border-color: #11a8b8 transparent #11a8b8 transparent;
  animation: dual-ring 1.2s linear infinite;
}

@keyframes dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
