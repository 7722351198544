.create-floor__button {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  height: 43px;
  background-color: #f7f8fa;
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: #3d5170;
  font-family: Roboto;
  line-height: 20.02px;
  letter-spacing: 0.15px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 17px;
}

.create-floor__button--valid {
  background: rgba(208, 250, 254, 0.5);
}

.create-floor__button--error {
  color: #f27171;
}

textarea {
  margin-bottom: 10px;
}

textarea::placeholder {
  color: #3d5170;
  opacity: 30%;
  font-style: italic;
  font-size: 14px;
}

.create-floor--readonly {
  cursor: default; /* Prevents the text cursor from appearing */
}

.create-floor__name {
  width: 100%;
  background-color: #ffffff;
  border: none;
  height: auto;
  min-height: 49px;
  border-bottom: 1px solid #c4c4c4;
  border-radius: 0px 0px 10px 10px;
  color: #3d5170;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.15px;
  padding: 10px 15px;
  resize: vertical;
}

.create-floor__name--filled {
  border-bottom: 1px solid #d0fafe;
  background: #d0fafe;
}

.create-floor__name--error {
  border-bottom: 3px solid #f27171;
}

.create-floor__green-tick {
  opacity: 0.2;
}

.create-floor__green-tick--filled {
  opacity: initial;
}

.create-floor__green-tick--error {
  display: none;
}
