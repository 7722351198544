.info {
  width: 100%;
  font-size: 1em;
  margin-top: 10px;
  text-transform: uppercase;
  color: white;
  border-radius: 64px;
  text-align: center;
  padding: 4px 6px;
}

.info--error {
  background: #f27171;
}

.info--success {
  background: #83d9e1;
}

.info--basic {
  background: #11a8b8;
  text-transform: unset;
  font-size: 12px;
}
