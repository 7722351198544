.back-header {
  width: 100%;
  height: 58px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.back-header__white {
  background: #ffffff;
}

.back-header__white--modal {
  background: #ffffff;
}

.back-header__green {
  background: #d0fafe;
}

.deficiency-type__title {
  font-size: 16px;
  font-weight: 500;
  color: #3d5170;
  margin-left: 24px;
}

.back-header__download {
  display: flex;
  align-items: center;
}

.back-header__download > p {
  font-weight: 500;
  font-size: 16px;
  color: #3d5170;
  margin-right: 15px;
}

.back__title {
  display: flex;
  gap: 10px;
  font-weight: 500;
  font-size: 18px;
  color: #3d5170;
  cursor: pointer;
}

@media only screen and (min-width: 1000px) {
  .back-header {
    height: 78px;
    padding: 30px 35px 0 35px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}

@media only screen and (max-width: 1140px) {
  .back-header__download > p {
    display: none;
  }
}
