.block-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 16px 10px 16px !important;
  background-color: #ffffff;
  box-shadow: none;
  border: none;
  font-size: 14px;
  border-radius: 8px 8px 0px 0px;
  height: 40px;
  color: #3d5170;
  font-weight: 500;
}

.block-button--gray {
  background-color: #8493ab;
  color: #f7f8fa;
}

.block-button--light-gray {
  background-color: #f7f8fa;
  color: #3d5170;
}

.block-button--standalone {
  border-radius: 8px;
  border-bottom: 1px solid #ced3dc;
}

.block-button__right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.block-button--readonly {
  cursor: default;
  pointer-events: none;
}
