.container {
  position: relative;
  width: 80px;
  height: 80px;
  cursor: pointer;
}

.container__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
