.customer-deficiencies {
  width: 100%;
  padding: 12px;
}

.customer-deficiencies__heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.customer-deficiencies__title {
  display: flex;
  align-items: center;
  color: #3d5170;
  font-size: 16px;
  font-weight: 500;
}

.customer-deficiencies__title > img {
  margin-right: 16px;
}

.customer-deficiencies__create-deficiency {
  display: flex;
  align-items: center;
  color: #3d5170;
  font-size: 14px;
  font-weight: 500;
}

.customer-deficiencies__create-deficiency > p {
  margin-right: 17px;
}

@media only screen and (min-width: 1000px) {
  .customer-deficiencies {
    background-color: #fff;
    border-radius: 12px;
    padding: 36px 48px;
  }
}
