:root {
  --police-blue: #3d5170;
}

.sub-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0px 15px 0px;
  font-size: 18px;
  color: var(--police-blue);
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.15px;
  text-align: left;
}

.sub-menu__label {
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-right: 10px;
  margin-top: 0;
  max-width: 100%;
}

.sub-menu__label--stretched {
  justify-content: space-between;
  width: 100%;
}

.sub-menu__label--hide {
  display: none;
}
