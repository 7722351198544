.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal-content {
  background: white;
  padding: 16px;
  border-radius: 16px;
  width: 480px;
  max-height: 700px;
  overflow-y: auto;
  position: relative;
  border-bottom: 2px solid #8493ab;
  background: #f2f2f2;
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.name {
  font-weight: 600;
  font-size: 20px;
  color: #3d5170;
}

.temporer {
  border-bottom: 1px solid #ced3dc;
  border-radius: 10px;
  background: #ffffff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.typeissue {
  border-bottom: 1px solid #ced3dc;
  border-radius: 10px;
  background: #ffffff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 10px;
}

.services__issue-type {
  background: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #c4c4c4;
}

.services__issue-type__buttons {
  display: flex;
  justify-content: space-between;
  opacity: 0.75;
  padding: 10px 0px;
  width: 100%;
}

.services__issue-type__button {
  color: #8493ab;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  border-radius: 8px;
  border: 2px #8493ab solid;
  padding: 7px;
  width: 49%;
  text-align: center;
  cursor: pointer;
}

.services__issue-type__button--active {
  background-color: #11a8b8;
  color: #fff;
  border-color: #11a8b8;
}

.services__issue-type__infestation {
  background-color: #fff;
  border-bottom: 1px solid #c4c4c4;
  border-radius: 10px;
  margin-bottom: 12px;
}

.issue-type__infestation-title {
  background-color: #f2f2f2;
  color: #3d5170;
  font-size: 16px;
  padding: 12px;
  font-weight: 500;
}

.issue-type__infestation-item {
  padding: 10px 17px;
  color: #3d5170;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header {
  font-size: 16px;
  font-weight: 500;
  color: #3d5170;
}

.header-5 {
  font-size: 14px;
  font-weight: 500;
  color: #3d5170;
}
