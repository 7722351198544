.deficiency-type h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: #3d5170;
}

.deficiency-type h4 {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15;
  color: #3d5170;
  margin-top: 3px;
}
