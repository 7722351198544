.picker {
  width: 100%;
  border: none; /* Remove border */
  background-color: transparent; /* White background */
  font-weight: normal;
  font-size: 18px;
  border-radius: 10px /* Rounded top corners */;
  overflow: hidden;
}

.header {
  background-color: #8493ab;
  color: white;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
  padding: 8px;
}

.header--flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.scrollable-list {
  overflow-y: auto;
  height: 96px;
  background-color: white;
  text-align: center;
}

.scrollable-list--dark {
  background-color: #edeff5;
}

.scrollable-list--large {
  height: 126px;
}
.disable-scroll {
  overflow-y: hidden;
}
.list-item {
  padding: 8px;
  cursor: pointer;
  height: 32px;
  border-bottom: none; /* Remove bottom border */
  color: black; /* Black text color */
  opacity: 0.3;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.list-item--large {
  height: 42px;
}

.list-item:last-child {
  border-bottom: none;
}

.selected {
  font-weight: 700;
  opacity: 1;
}
