.tab-button {
  height: 26px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #3d5170;
  background: transparent;
  border-radius: 8px;
  border-color: transparent;
  border-width: 1px;
  justify-content: center;
  align-items: center;
  float: left;
  display: flex;
  margin-right: 12px;
  padding: 12px 16px 11px;
}

.tab-button--clicked {
  background: #ffffff;
}
