.document-uploader {
  margin-bottom: 10px;
}

.document-uploader__button {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  height: 43px;
  background-color: #f7f8fa;
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: #3d5170;
  font-family: Roboto;
  line-height: 20.02px;
  letter-spacing: 0.15px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 17px;
}

.document-uploader__name {
  width: 100%;
  background-color: #ffffff;
  border: none;
  height: auto;
  min-height: 49px;
  border-bottom: 1px solid #c4c4c4;
  border-radius: 0px 0px 10px 10px;
  color: #3d5170;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.15px;
  padding: 10px 15px;
  resize: vertical;
}

.document-uploader__name-placeholder {
  opacity: 30%;
  font-style: italic;
}
