.card {
  display: flex;
  width: 100%;
  height: 95px;
  padding: 12px;
  align-items: flex-start;
  border-radius: 10px;
  border-bottom: 1px solid #c4c4c4;
  cursor: pointer;
}

.card--white {
  background-color: #ffffff;
}

.card--green {
  background-color: #d0fafe;
}

.card--grayed-out {
  opacity: 0.5;
}

.card__date {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px;
  justify-content: center;
  border-color: transparent;
  height: 56px;
  min-width: 56px;
  background: #ebedf1;
  color: #3d5170;
  border-radius: 28px;
  margin: auto;
}

.card__date--small {
  font-size: 12px;
  gap: 0;
}

.card__date--approved {
  background-color: rgba(14, 170, 0, 0.3);
  color: rgba(14, 170, 0, 1);
}

.card__date > * {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  gap: 0;
  line-height: 10px;
}

.card__info {
  padding-left: 16px;
  flex-grow: 1;
  overflow: hidden;
}

.card__info__title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 24px;
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #3d5170;
}

.card__info__additional-infos {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #8493ab;
  font-size: 16px;
  font-weight: 400;
}

.exclamation {
  width: 16.62px;
  height: 16.67px;
}
