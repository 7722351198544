.list__item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.empty-list {
  height: 49px;
  padding: 15px 0px;
  color: #3d5170;
  opacity: 50%;
}

.list__card--monitortype__span {
  overflow: hidden;
  text-overflow: ellipsis;
}
