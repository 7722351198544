.search-container {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-end;
}

.search-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 28px;
  border: none;
  outline: none;
  background: rgba(61, 81, 112, 0.17);
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  z-index: 1;
}

.search-button.expanded {
  background-color: white;
  height: 32px;
}

.search-button svg {
  width: 18px;
  height: 18px;
}

.search-input {
  height: 32px;
  border: none;
  border-bottom: 1px solid #ced3dc;
  border-radius: 50px;
  outline: none;
  padding: 0 10px;
  position: absolute;
  right: 12px;
  margin-right: 8px;
  width: 0;
  background: white;
  opacity: 0;
  transition:
    width 0.3s ease-in-out,
    opacity 0.3s ease-in-out,
    right 0.5s ease-out;
  font-size: 14px;
  color: #3d5170;
}

.search-input.expanded {
  right: 0;
  width: calc(100% - 12px);
  opacity: 1;
}
