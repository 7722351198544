.list__row {
  padding: 16px 5px;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  /* box-shadow: 0px 1px 0px 0px #CED3DC inset; */
  border-top: 1px solid #ced3dc;
  cursor: pointer;
}

.list__row--reverse-border {
  border-bottom: 1px solid #ced3dc;
  border-top: none;
}

.list__row--reverse-border:last-child {
  border-bottom: none;
}

.list__item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  justify-content: space-between;
}

.list__row--no-border:first-child {
  border: none;
}
