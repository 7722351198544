.check-in {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 3%;
  color: #3d5170;
}

.check-in__button {
  width: 250px;
  height: 49px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #5dc9d4;
  outline: none;
  border: none;
  border-radius: 24px;
  color: white;
  margin-top: 16px;
  margin-bottom: 140px;
  padding: 12px 16px;
  gap: 6px;
}

.check-in__button:disabled {
  background-color: #ced3dc;
}

.check-in__km {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background: rgba(237, 239, 245, 0.8);
  border-radius: 10px;
  margin-top: 7%;
  padding: 19px 44px;
  text-align: center;
}

.check-in__km--entered {
  background: #d0fafe;
}

.check-in__km h1 {
  font-size: 1em;
}

.check-in__km--container {
  display: flex;
  border-bottom: 1px solid black;
}

.check-in__km--input {
  width: 100%;
  outline: none;
  border: none;
  background: transparent;
  color: #3d5170;
  font-size: 2em;
  text-align: center;
  z-index: 4;
  margin-left: 50px;
  height: fit-content;
}

.check-in__km--unit {
  width: 50px;
  font-size: 2em;
  user-select: none;
}

.check-in__error {
  width: 100%;
  height: 25px;
  margin: 12px 12px 0 12px;
  background-color: #f27171;
  border-radius: 64px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
