.list {
  border-bottom: 1px solid #c4c4c4;
  background: #ffffff;
  border-radius: 10px;
}

.list__title {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #3d5170;
  padding: 12px;
  background-color: #f7f8fa;
  height: 43px;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: space-between;
}

.list > ul > li {
  display: flex;
  justify-content: space-between;
  padding: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #3d5170;
}

.list > ul > li > :nth-child(2) {
  font-weight: 500;
}

@media only screen and (min-width: 1000px) {
  .list > ul > li {
    border-bottom: 1px solid #ced3dc;
  }
}
