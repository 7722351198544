.note {
  width: 100%;
  min-height: 110px;
  border: none;
  border-bottom: 1px solid #ced3dc;
  border-radius: 10px;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
}

.note--white {
  background-color: #ffffff;
}

.note--gray {
  background-color: #edeff5;
}

.note--readonly {
  cursor: default;
  color: #3d5170;
}

.note--service {
  min-height: 310px;
}

.note--comment {
  min-height: 45px;
  height: 45px;
  padding: 10.5px 8px;
  color: #3d5170;
}

textarea::placeholder {
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  opacity: 50%;
}
