.quantity-button {
  background-color: #3d5170;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  border: none;
  opacity: 0.20000000298023224;
}

.quantity-button--clicked {
  opacity: 1;
}
