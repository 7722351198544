.content-wrapper {
  width: 100%;
}

.content {
  width: 100%;
  padding: 12px;
}

.technician-list {
  background-color: white;
  border-radius: 10px;
  overflow: auto;
  max-height: 55vh;
}
