.content-wrapper {
  width: 100%;
  padding: 12px;
  margin-top: 8px;
}
.services__issue-type {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  border-bottom: 1px #ced3dc solid;
  padding: 10px;
  margin-top: 16px;
}
.services__issue-type--title {
  color: #3d5170;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 13px;
  padding-left: 5px;
}
.services__issue-type__buttons {
  display: flex;
  justify-content: space-between;
  opacity: 0.75;
}
.services__issue-type__button {
  color: #8493ab;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  border-radius: 8px;
  border: 2px #8493ab solid;
  padding: 7px;
  width: 49%;
  text-align: center;
  cursor: pointer;
}
.services__issue-type__button--active {
  background-color: #11a8b8;
  color: #fff;
  border-color: #11a8b8;
}
.error-message {
  color: white;
  background: #f27171;
  border-radius: 64px;
  text-align: center;
  padding: 4px 6px;
}
