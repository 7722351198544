.custom-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.custom-slider__percentage {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #8493ab;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.15px;
  margin-top: -12px;
  padding: 0 4px;
}

.custom-slider__current-value {
  position: absolute;
  left: calc(var(--value) * 1%);
  transform: translateX(-50%);
  color: #11a8b8;
}
