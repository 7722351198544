.card {
  display: flex;
  justify-content: space-between;
  height: 52px;
  gap: 16px;
  flex-direction: row;
  align-items: center;
  padding-left: 11px;
  padding-right: 11px;
  align-items: flex-start;
  border-bottom: 1px solid #ced3dc;
  background-color: white;
}

.card:first-child {
  border-start-start-radius: 10px;
  border-start-end-radius: 10px;
}

.card:last-child {
  border-bottom: none;
  border-end-start-radius: 10px;
  border-end-end-radius: 10px;
}

.card__user-info {
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  gap: 10px;
}

.card__user-info__name {
  color: var(--pestix-grau-05, #3d5170);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin-top: auto;
  margin-bottom: auto;
}

.card__button-wrapper {
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  opacity: 0.2;
}

.card--selected {
  background-color: #d0fafe;
}

.card__button-wrapper--selected {
  opacity: 1;
}
