.card {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 95px;
  background: #ffffff;
  border-bottom: 1px solid #c4c4c4;
  border-radius: 10px;
  overflow: hidden;
  padding-right: 50px;
  cursor: pointer;
}

.card__completed {
  opacity: 0.25;
}

.card__circle {
  display: flex;
  width: 56px;
  min-width: 56px;
  height: 56px;
  justify-content: center;
  align-items: center;
  background-color: rgba(206, 211, 220, 0.3);
  color: #3d5170;
  border-radius: 28px;
  margin: 12px;
}

.card__circle--today {
  color: #11a8b8;
  background: rgba(131, 217, 225, 0.3);
}

.card__circle--past {
  color: #f29100;
  background: #f5e2ee;
}

.card__info {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: flex-start;
  text-align: left;
  gap: 4px;
}

.card__info__title {
  font-weight: bold;
  color: #3d5170;
}

.card__info__title--upcoming {
  font-style: italic;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(100% - 36px);
  font-weight: lighter;
}

.card__info__address {
  color: #8493ab;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(100% - 36px);
}

.card__info__type {
  color: #069aa9;
  background: rgba(237, 239, 245, 0.8);
  border-radius: 25px;
  font-size: 12px;
  padding: 3px 6px;
}

.card__info__type--deficiency {
  color: #f29100;
}

@media only screen and (min-width: 1000px) {
  .card {
    padding-right: 10px;
  }

  .card__info {
    flex-direction: row;
    align-items: center;
  }

  .card__info__type {
    padding: 3px 20px;
  }

  .card__info__title {
    margin-right: 20px;
  }
}
