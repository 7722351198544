.sign-in {
  display: flex;
  justify-content: center;
  background: linear-gradient(180deg, #02ccd9 0%, #0097a7 71.29%);
  height: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  gap: 12px;
  font-size: 1em;
  width: 80%;
}

.form input {
  padding: 12px 16px 12px 16px;
  color: #3d5170;
  background: #ffffff;
  border-radius: 24px;
  border: none;
  text-align: center;
  width: 100%;
  font-size: 1em;
}

.form__email,
.form__password,
.form__button,
.error-message {
  width: 100%;
  font-size: 1em;
}

.form__button {
  color: white;
  background: #3d5170;
  border-radius: 24px;
  padding: 12px 16px;
  border: none;
  cursor: pointer;
}

.error-message {
  color: white;
  background: #f27171;
  border-radius: 64px;
  text-align: center;
  padding: 4px 6px;
}

.form__email--error {
  color: #b32833 !important;
  font-style: italic;
}

.navigation-button {
  color: #83d9e1;
  font-size: 1em;
  text-decoration: none;
}

.logo {
  position: absolute;
  bottom: 21px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.welcome-container {
  position: relative;
  z-index: 19;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: 100%;
}

.welcome-container__message {
  position: absolute;
  top: 30%;
  text-align: center;
  font-size: 1em;
  color: #3d5170;
}

.welcome-container__redirect-button {
  position: absolute;
  bottom: 20%;
  border: none;
  background: none;
  cursor: pointer;
}

.form__confirmation-message {
  color: white;
  font-size: 1em;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}
