.container {
  position: relative;
  width: 80px;
  height: 80px;
  cursor: pointer;
}

.container__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.container__button {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  background-color: #3d5170;
  color: white;
  border: none;
  border-radius: 20%;
  opacity: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
}
