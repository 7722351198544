.content {
  width: 100%;
  padding: 12px;
}

.upload-documents-type {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  border-bottom: 1px #ced3dc solid;
  padding: 17px;
  margin-top: 16px;
}

.upload-documents-type--title {
  color: #3d5170;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 13px;
  padding-left: 5px;
}

.upload-documents__buttons {
  display: flex;
  justify-content: space-between;
  opacity: 0.75;
}

.upload-documents__button {
  color: #8493ab;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  border-radius: 8px;
  border: 2px #8493ab solid;
  padding: 7px;
  width: 49%;
  text-align: center;
  cursor: pointer;
}

.upload-documents__button--active {
  background-color: #11a8b8;
  color: #fff;
  border-color: #11a8b8;
}

.upload-documents__type {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px 10px 10px 10px;
}
