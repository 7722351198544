.content-wrapper {
  width: 100%;
}

.content {
  width: 100%;
  padding: 12px;
  margin-top: 8px;
  color: #3d5170;
}

.monitor-status--title {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 16px;
}

.monitor-status__monitortype--name {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 24px;
}

.monitor-status__propertyarea--name {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 24px;
  opacity: 0.5;
  overflow: hidden;
  text-overflow: ellipsis;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.button-adjust-position {
  border-radius: 8px;
  width: fit-content;
  padding: 6px 22px;
  height: 38px;
  margin-top: 19px;
}

.error-message {
  margin-top: 20px;
  margin-bottom: 20px;
  color: white;
  background: #f27171;
  border-radius: 64px;
  text-align: center;
  padding: 4px 6px;
}

.last-services {
  background-color: white;
  border-radius: 10px;
  margin-top: 10px;
  color: #3d5170;
  border-bottom: 1px #c4c4c4 solid;
  padding: 13px 20px 13px 20px;
}

.last-services__title {
  font-size: 14px;
  font-weight: 500;
  color: #3d5170;
  margin-bottom: 6px;
}

.monitor-status__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
