.content-wrapper {
  margin-right: 14px;
  margin-left: 14px;
  margin-top: 18px;
}

.content {
  width: 100%;
}

.title {
  font-size: 20px;
  color: #3d5170;
  font-weight: 700;
  display: block;
  width: 100%;
  margin-bottom: 16px;
}

.no-signature {
  display: block;
  width: 100%;
  text-align: right;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  line-height: 18px;
  color: #3d5170;
  margin-top: 4px;
  margin-bottom: 16px;
}

.no-signature-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  align-items: center;
  justify-content: center;
}

.no-signature-content {
  padding: 20px;
  max-width: 350px;
  position: relative;
  display: grid;
  margin: auto;
  gap: 6px;
  margin-top: 40%;
}

.no-signature__title {
  text-align: center;
  color: #3d5170;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.04px;
}

.button-wrapper {
  display: flex;
  justify-content: center;
}

.button-wrapper--grayed-out {
  opacity: 0.2;
}
