.worker {
  display: flex;
  width: 100%;
  max-width: 500px;
  height: 80px;
  padding: 12px;
  background: #ffffff;
  border-bottom: 1px solid #11a8b8;
  border-radius: 10px;
  margin-bottom: 12px;
}

.worker--absent {
  opacity: 0.4;
}

.worker__avatar {
  width: 56px;
  height: 56px;
  position: relative;
}

.worker__avatar img {
  width: 100%;
  height: 100%;
  border-radius: 28px;
}

.worker__status {
  position: absolute;
  top: 0;
  right: 0;
  width: 11px;
  height: 11px;
  border-radius: 20px;
  background-color: #c4c4c4;
}

.worker__status--online {
  background-color: #14ff00;
}

.worker__status--absent {
  background-color: #f27171;
}

.worker__info {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  flex-grow: 1;
  gap: 4px;
  justify-content: center;
  width: min-content;
}

.worker__info h1 {
  font-weight: 500;
  font-size: 16px;
  color: #3d5170;
}

.worker__info h2 {
  font-weight: 400;
  font-size: 16px;
  color: #8493ab;
}

.worker__contact {
  display: flex;
  flex-direction: column;
}

.worker__contact a {
  color: #3d5170;
}
