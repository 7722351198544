.content {
  position: absolute;
  /* top: -30px; */
  width: fit-content;
  margin: auto;
  display: flex;
  gap: 3px;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.content--pulled-up {
  top: -20px;
}

.timer {
  width: fit-content;
  background-color: #11a8b8;
  padding-right: 7px;
  padding-left: 7px;
  border-radius: 64px;
  color: white;
  font-size: 12px;
}

.red-circle {
  background-color: #ff0000;
  width: 11px;
  height: 11px;
  border-radius: 10px;
}
