.filters {
  width: 450px;
  height: fit-content;
  background-color: #e3f9fc;
  position: relative;
  top: 150px;
  border-radius: 8px;
  padding: 20px;
  z-index: 9999;
}

.filters__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.filter-container {
  display: flex;
  margin: 10px 0;
}

.filter {
  display: grid;
  gap: 6px;
  width: 50%;
}

.filters__type {
  color: #8493ab;
  font-size: 13px;
  font-weight: 500;
}

.custom-date-picker {
  width: 156px;
  border: none;
}

.date-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 10px;
  border-radius: 8px;
  width: 200px;
  border-bottom: 1px solid #ced3dc;
  background-color: #fff;
  height: 40px;
  z-index: 9999;
}

.filters__reset-text {
  color: #11a8b8;
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
}

.filters__title {
  font-weight: 500;
  color: #3d5170;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.filter-date__label {
  font-weight: 400;
  font-size: 12px;
  color: #8493ab;
}
