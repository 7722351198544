* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  /* font-size: 1em; */
}

body {
  overflow: hidden;
  touch-action: none;
  overscroll-behavior: contain;
}

html,
body,
#root {
  height: 100%;
  font-size: 1em;
  overflow-y: hidden;
}

button {
  cursor: pointer;
  user-select: none;
  font-size: 1em;
}

.hover-button {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  padding: 16px;
  gap: 8px;
  width: 56px;
  height: 56px;
  color: white;
  background: #11a8b8;
  border-radius: 28px;
  outline: none;
  border: none;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.hover-button-bearbeiten {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  padding: 16px;
  gap: 8px;
  width: 56px;
  height: 56px;
  color: white;
  background: #11a8b8;
  border-radius: 28px;
  outline: none;
  border: none;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.disable {
  pointer-events: none;
}

.leaflet-control-attribution {
  display: none;
}
