.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  z-index: 9999;
}

.modal__content {
  background-color: #fff;
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translate(0, -50%);
  padding: 20px;
  border-radius: 4px;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal__alert-icon {
  margin-bottom: 40px;
}

.modal__text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.002em;
  color: #3d5170;
  flex: none;
  order: 0;
  flex-grow: 0;
  width: 60%;
  margin: auto;
  height: fit-content;
}

.modal__total {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.002em;
  color: #3d5170;
  display: -webkit-box;
  width: 30%;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 16px;
  height: fit-content;
}

.modal__note {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  letter-spacing: 0.002em;
  color: #3d5170;
  padding-top: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
  width: 60%;
  margin: auto;
  height: fit-content;
}

.modal__buttons {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  gap: 8px;
}

.modal__buttons--text-version {
  display: grid;
  justify-content: center;
  margin-top: 22px;
  gap: 12px;
}

.modal__buttons--close {
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 8px;
  width: 56px;
  height: 56px;
  background: #f27171;
  border-radius: 28px;
  border-color: transparent;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.modal__buttons--close-dark {
  background: #3d5170;
}

.modal__buttons--close-text {
  padding: 16px;
  gap: 8px;
  background: #cb3844;
  border-radius: 28px;
  border-color: transparent;
  width: 271px;
  height: 56px;
  order: 1;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #fff;
}

.modal__buttons--accept {
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 8px;
  width: 56px;
  height: 56px;
  background: #069aa9;
  border-radius: 28px;
  border-color: transparent;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.modal__buttons--accept-text {
  padding: 16px;
  background: #069aa9;
  border-radius: 28px;
  border-color: transparent;
  width: 271px;
  height: 56px;
  order: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #fff;
}

@media only screen and (min-width: 1000px) {
  .modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(231, 231, 231, 0.9);
    z-index: 9999;
  }

  .modal__content {
    background-color: #fff;
    position: relative;
    margin: auto;
    width: 580px;
    top: 50%;
    transform: translate(0, -50%);
    padding: 20px;
    border-radius: 24px;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
