.content-wrapper {
  width: 100%;
  padding: 12px;
}

.content {
  width: 100%;
  padding: 12px;
}

.map {
  margin-bottom: 20px;
}

.map--image {
  width: 100%;
  height: auto;
  padding: 15px 5px 10px 5px;
}

.map-container {
  position: relative;
  width: 100%;
  height: 500px;
}

.pin {
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: red;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.image {
  margin-bottom: 20px;
  position: relative;
  background-color: #fff;
  border-radius: 15px;
  border-bottom: 1px solid #c4c4c4;
  height: 500px;
}

.text {
  font-size: 16px;
  font-weight: 500;
  color: #8493ab;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.last-services {
  background-color: white;
  border-radius: 10px;
  margin-top: 10px;
  color: #3d5170;
  border-bottom: 1px #c4c4c4 solid;
  padding: 13px 20px 13px 20px;
}

.last-services__title {
  font-size: 14px;
  font-weight: 500;
  color: #3d5170;
  margin-bottom: 6px;
}
