.map-container {
  height: 100% !important;
  background: transparent;
}

.pin-container {
  background: none;
  border: none;
  text-align: center;
}
.map-pin {
  background-color: #11a8b8;
  color: white;
  width: 45px;
  height: 45px;
  border-radius: 2px;
}

.map-pin.selected {
  background-color: #82e0ec;
  width: 50px;
  height: 50px;
  border: 2px solid #11a8b8;
}

.pin-name,
.pin-number {
  color: #edeff5;
  text-align: center;
  white-space: nowrap;
  font-size: 1em;
}

.selected .pin-name,
.selected .pin-number {
  color: #11a8b8;
}

.pin-name {
  font-weight: 600;
  letter-spacing: 0.3px;
}

.pin-number {
  font-weight: 400;
  letter-spacing: 1px;
}

.map-pin::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid #11a8b8;
  z-index: -1;
}

.map-pin.selected::after {
  border-top-color: 2px solid #ffd580;
}
