.list {
  border-bottom: 1px solid #c4c4c4;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 10px;
}

.list__subtitle {
  font-size: 16px;
  color: #3d5170;
  padding: 12px 0 18px 17px;
  opacity: 0.5;
}

.list__title {
  font-size: 16px;
  color: #3d5170;
  padding: 12px 0 0 17px;
}

.list__card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px 12px 0px;
  margin: 0 17px;
  border-bottom: 1px solid #c4c4c4;
  cursor: pointer;
}

.list__card:first-child {
  box-shadow: 0px 2px 0px 0px #ced3dc inset;
  margin-top: 15px;
}

.no-shadow .list__card:first-child {
  box-shadow: none;
}

.list__card:last-child {
  border-bottom: none;
}

.list__item {
  font-size: 14px;
  color: #3d5170;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
}

.list svg {
  color: #8493ab;
}
