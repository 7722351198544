.content-wrapper {
  width: 100%;
  padding: 12px;
}

.actions {
  border-bottom: 1px solid #c4c4c4;
  background: #ffffff;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.actions__title {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #3d5170;
  padding: 12px 0 0 17px;
  background-color: #f7f8fa;
  height: 43px;
  border-radius: 10px 10px 0px 0px;
}

.actions__content {
  color: #3d5170;
  font-size: 14px;
  font-weight: 500;
  min-height: 40px;
  padding: 12px 0 12px 17px;
}

.materials {
  margin-top: 20px;
}

.map {
  margin-top: 20px;
}

.map-content {
  height: 500px;
}

@media only screen and (min-width: 1000px) {
  .content-wrapper {
    background-color: #eceef3;
    padding: 36px 45px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom: 1px #c4c4c4 solid;
  }
}
