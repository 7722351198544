.custom-email-input {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  height: 43px;
  background-color: #f7f8fa;
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: #3d5170;
  display: flex;
  align-items: center;
  line-height: 20.02px;
  letter-spacing: 0.15px;
  justify-content: space-between;
  padding: 17px;
}

.custom-email-input__email {
  width: 100%;
  background-color: #ffffff;
  border: none;
  color: #3d5170;
  gap: 8px;
  padding-left: 17px;
  padding-right: 17px;
  border-end-start-radius: 10px;
  border-end-end-radius: 10px;
}

.custom-email-input__email input {
  width: 90%;
  border: 0;
  color: #3d5170;
  margin: 0;
  height: 49px;
}

.custom-email-input__box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #c4c4c4;
}

.custom-email-input__box:first-child {
  border-top: none;
}
