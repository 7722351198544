.fullscreen-preview {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: #a0a0a080;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen-image-container {
  position: relative;
  max-width: 80%;
  max-height: 400px;
}

.fullscreen-image {
  max-width: 100%;
  max-height: 400px;
  border-radius: 20px;
}

.fullscreen-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  color: white;
  border: none;
  padding: 5px;
  z-index: 10000;
  cursor: pointer;
}

@media (max-width: 999px) {
  .fullscreen-preview {
    max-width: 100%;
  }
}
