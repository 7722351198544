.deficiency-details {
  border-bottom: 1px solid #11a8b8;
  display: grid;
  width: 100%;
  height: 155px;
  padding: 25px 45px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: transparent;
  box-shadow: 0px 4px 4px 0px #0000000d;
  position: relative;
  align-items: center;
  background: #ffffff;
}

.deficiency-details__responsibility {
  background: rgba(237, 239, 245, 0.8);
  border-radius: 25px;
  font-size: 12px;
  padding: 2px 10px;
  color: #11a8b8;
  width: 120px;
  margin-right: 5px;
  text-align: center;
  height: 20px;
}

.deficiency-details__first-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.deficiency-details__first-row--type {
  display: flex;
  gap: 25px;
  align-items: center;
}

.deficiency-details__location {
  font-size: 16px;
  color: #8493ab;
  font-weight: 400;
}

.deficiency-details__type {
  font-size: 16px;
  font-weight: 500;
  color: #3d5170;
}

.deficiency-details__circle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 4px;
  border-color: transparent;
  min-width: 56px;
  height: 56px;
  background-color: #83d9e1;
  color: #11a8b8;
  border-radius: 28px;
}
