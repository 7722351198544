.absence-wrapper {
  background-color: #fcfcfe;
}

.new-absence {
  width: auto;
  text-align: center;
  margin: 20px 15px 0 15px;
  background-color: transparent;
}

.new-absence h2 {
  font-size: 16px;
  color: #3d5170;
}

.new-absence__type {
  width: 100%;
  margin-top: 20px;
}

.new-absence__dates {
  display: flex;
  gap: 7px;
  height: 129px;
}

.new-absence__dates--from,
.new-absence__dates--to {
  width: 100%;
}

.new-absence__options {
  display: flex;
  height: 44px;
  align-items: center;
  justify-content: space-between;
  padding: 12px 14px;
  background: #edeff5;
  border-radius: 10px;
  margin-top: 14px;
  text-align: left;
}

.new-absence__options svg {
  width: 20px;
  height: 20px;
  color: #069aa9;
  opacity: 0.2;
}

.new-absence__options--active {
  background: #d0fafe;
}

.new-absence__options--active svg {
  opacity: 1;
}

.new-absence__options--overtime {
  display: flex;
  gap: 10px;
  text-align: left;
  height: 44px;
  justify-content: left;
  align-items: center;
  padding: 12px 14px;
  margin-top: 14px;
}

.new-absence__options--overtime input {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background: #ffffff;
  border: 1px solid #8493ab;
  text-align: center;
}

.new-absence__note {
  width: 100%;
  height: 100px;
  margin-top: 12px;
}

.new-absence__note textarea {
  width: 100%;
  height: 100%;
  border: 1px solid #8493ab;
  border-radius: 10px;
  background-color: white;
  padding: 10px 15px;
  font-size: 16px;
}

.new-absence__note textarea::placeholder {
  color: #3d5170;
  font-style: italic;
  opacity: 0.6;
}

.new-absence__note textarea:focus {
  outline: none;
}

.new-absence__actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 8px;
}

.new-absence__actions button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 56px;
  outline: none;
  border: none;
  color: white;
}

.new-absence__actions--cancel {
  background: #c4c4c4;
}

.new-absence__actions--cancel svg {
  width: 20px;
  height: 20px;
}

.new-absence__actions--submit {
  background: #3d5170;
}

.new-absence__actions--submit svg {
  width: 35px;
  height: 35px;
}

.new-absence__error {
  height: 32px;
  margin: 12px 0;
  padding: 4px;
  font-size: 12px;
  background-color: #f27171;
  border-radius: 64px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
