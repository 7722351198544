.title {
  color: #3d5170;
}

.pdf-preview {
  height: 100vh;
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
  margin-top: 20px;
}
