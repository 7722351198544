.create-floor {
  width: 100%;
  height: calc(100% - 60px);
  background: rgba(237, 239, 245, 0.8);
  overflow-y: scroll;
}

.content-wrapper {
  width: 100%;
}

.content {
  width: 100%;
  padding: 12px;
}

.content__map {
  width: 85%;
  height: auto;
  display: block;
  margin: 0 auto;
}
