.toggle {
  display: flex;
  height: 43px;
  align-items: center;
  justify-content: space-between;
  padding: 12px 14px;
  border-radius: 10px;
  margin-top: 14px;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
}

.toggle--gray {
  background: #edeff5;
}

.toggle--white {
  background: #ffffff;
}

.toggle svg {
  width: 20px;
  height: 20px;
  color: #069aa9;
  opacity: 0.2;
}

.toggle--active {
  background: #d0fafe;
}

.toggle--active svg {
  opacity: 1;
}
