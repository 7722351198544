.dashboard-home {
  height: fit-content;
  padding: 24px 12px;
}

.dashboard-home-tasks {
  padding: 0;
}

.dashboard__past-appoitments {
  background-color: #f29100;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  font-family: "Roboto";
  padding: 5px;
  border-radius: 64px;
  text-align: center;
  cursor: pointer;
}

.tasks-today__header h3 {
  font-size: 18px;
}

.tasks-today__header h3 span {
  color: #8493ab;
}

.tasks-today__header button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 28px;
  border: none;
  outline: none;
  background: rgba(61, 81, 112, 0.17);
  border-radius: 50px;
}

.tasks-today__header button svg {
  width: 18px;
  height: 18px;
}

.tasks-upcoming h3,
.tasks-tomorrow h3 {
  font-size: 18px;
  margin-left: 7px;
  margin-bottom: 8px;
}

.tasks-upcoming h3 span,
.tasks-tomorrow h3 span {
  color: #8493ab;
}

.tasks-upcoming,
.tasks-tomorrow {
  margin-top: 25px;
}
