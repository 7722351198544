.full-screen-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  position: absolute;
  bottom: 0;
  right: 0;
  border: none;
  outline: none;
  background: #000000;
  border-radius: 50px;
  margin: 7px;
  z-index: 900;
}
