.list {
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-direction: column;
}

.card {
  width: 100%;
  height: 95px;
  padding: 10px;
  border-radius: 10px;
  border-bottom: 1px solid #8493ab;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
}

.name {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;
  color: #3d5170;
}

.mail {
  font-size: 13px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;
  color: #8493ab;
}

.card__tags {
  display: flex;
  align-items: center;
}

.card__type--responsibility {
  background: rgba(237, 239, 245, 0.8);
  border-radius: 25px;
  font-size: 12px;
  padding: 2px 10px;
  color: #11a8b8;
  width: fit-content;
}

.tags {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.date {
  font-size: 13px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;
  color: #8493ab;
}
