@media only screen and (min-width: 1000px) {
  .customer-analysis {
    width: 100%;
    padding-top: 40px;
    border-radius: 12px;
    background-color: #fff;
  }

  .charts {
    background-color: #f7f8fa;
  }

  .customer-analysis__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    margin-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .customer-analysis__title {
    display: flex;
    align-items: center;
    color: #3d5170;
    margin-left: 12px;
    gap: 10px;
    font-size: 18px;
    font-weight: 500;
  }

  .thickline {
    background-color: #f7f8fa;
    border: 3px solid #ced3dc;
    border-radius: 4px;
    width: 90%;
    margin: auto;
  }

  .filters__reset-text {
    color: #11a8b8;
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;
  }

  .reset {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
