.images-container {
  padding: 0px 14px 14px 14px;
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
  background-color: white;
  text-align: center;
  border-radius: 0px 0px 10px 10px;
}

.images-container--empty {
  padding: 0px 14px 14px 14px;
  background-color: white;
  font-weight: 500;
  font-style: italic;
  font-size: 14px;
  line-height: 20px;
  color: #8493ab;
  border-radius: 0px 0px 10px 10px;
}
