.heading {
  color: #3d5170;
  font-size: 18px;
  font-weight: 700;
}

.heading--resolved {
  margin-top: 20px;
}

.tabs {
  width: 100%;
  padding: 12px;
  margin-top: 8px;
}

.buttons {
  display: flex;
  overflow: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
}
