.dashboard-navigation {
  width: 100%;
  height: 70px;
  padding-bottom: 25px;
  background: white;
}

.dashboard-navigation-links {
  display: flex;
  justify-content: space-around;
  height: 100%;
}

.dashboard-navigation-links > * {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  width: 100%;
  height: 100%;
  border: none;
  background: none;
  color: #3d5170;
  opacity: 0.4;
}

.active {
  border-top: 2px solid #11a8b8 !important;
  opacity: 1 !important;
}

.dashboard-navigation-links__label {
  color: #3d5170;
  text-decoration: none;
  font-weight: 500;
  margin-left: 15px;
  cursor: pointer;
}

@media only screen and (min-width: 1000px) {
  .dashboard-navigation {
    width: 300px;
    height: 100vh;
    display: flex;
    align-items: center;
    padding: 0;
  }

  .dashboard-navigation__logo {
    position: absolute;
    top: 60px;
    left: 32px;
    height: 30px;
    width: auto;
  }

  .dashboard-navigation-links {
    flex-direction: column;
    height: fit-content;
    width: 100%;
    cursor: pointer;
  }

  .dashboard-navigation-links > * {
    padding: 20px 24px;
    justify-content: flex-start;
    text-decoration: none;
  }

  .dashboard-navigation-settings {
    position: absolute;
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
  }

  .active {
    border-top: none !important;
    border-right: 3px solid #11a8b8;
    background-color: #e3f9fc;
  }
}
