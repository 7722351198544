.monitors__list {
  background-color: white;
  border-radius: 10px;
  margin-top: 10px;
  padding: 0px 20px 0px 20px;
  color: #3d5170;
  border-bottom: 1px #c4c4c4 solid;
}

.monitors__list__propertyarea {
  font-size: 14px;
  font-weight: 400;
  padding: 13px 24px 13px 0px;
}
.monitors__list__propertyarea--shadow {
  box-shadow: 0 4px 6px -7px #222;
}
.monitors__list__propertyarea--name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.list__card {
  height: auto;
  width: auto;
  font-size: 14px;
}

.list__card--monitortype {
  display: flex;
  gap: 8px;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.list__card--monitortype__span {
  overflow: hidden;
  text-overflow: ellipsis;
}
.image {
  position: relative;
  background-color: #fff;
  border-bottom: 1px solid #c4c4c4;
  height: 400px;
  width: 100%;
}
.image-container {
  width: 100%;
  margin-top: 20px;
  display: flex;
  gap: 20px;
}
