@media only screen and (min-width: 1000px) {
  .barchart {
    background-color: #f7f8fa;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 70px;
  }

  .barchart__text {
    margin-left: 100px;
  }

  .barchart__title {
    display: flex;
    margin-left: 12px;
    align-items: center;
    color: #3d5170;
    gap: 10px;
    font-size: 18px;
    font-weight: 500;
  }

  .barchart__data {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 20px;
  }
}
