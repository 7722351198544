.name {
  color: #8493ab;
  font-weight: 400;
  font-size: 12px;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.document-name {
  color: #3d5170;
  font-weight: 500;
  font-size: 16px;
}

.document-date {
  color: #8493ab;
  font-weight: 400;
  font-size: 16px;
}

.documents {
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
  border-bottom: 0.5px solid #ced3dc;
  cursor: pointer;
}

.documents:hover {
  background: #e3f9fc;
}

.title {
  color: #3d5170;
}

.pdf-preview {
  height: 100vh;
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
  padding: 20px;
  background: #f2f2f2;
}

.header-preview {
  border-bottom: 1px solid #ced3dc;
  display: flex;
  border-radius: 12px 12px 0px 0px;
  justify-content: space-between;
  padding: 20px;
  background-color: white;
  align-items: center;
}

.documents-flex__1 {
  width: 70%;
  display: flex;
  align-items: center;
}

.documents-flex__2 {
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.back-header__download {
  display: flex;
  align-items: center;
}

.back-header__download > p {
  font-weight: 500;
  font-size: 16px;
  color: #3d5170;
  margin-right: 15px;
}

.header-icon {
  display: flex;
  align-items: center;
  gap: 30px;
}

.cursor {
  cursor: pointer;
}
