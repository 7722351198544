.multiselect {
  width: 100%;
  margin-top: 20px;
}

.multiselect__title {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  height: 43px;
  background-color: #fff;
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: #3d5170;
  font-family: Roboto;
  line-height: 20.02px;
  letter-spacing: 0.15px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 17px;
}

.multiselect__options {
  width: 100%;
  background-color: #fff;
  padding: 17px;
  border-radius: 0px 0px 10px 10px;
}

.multiselect__option {
  color: #3d5170;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 10px;
  margin-bottom: 10px;
  font-weight: 500;
}
