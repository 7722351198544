.content-wrapper {
  width: 100%;
}

.content {
  width: 100%;
  padding: 12px;
}

.new-deficiency-type-name {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  /* 122.222% */
  letter-spacing: 0.15px;
  color: #3d5170;
}

@media only screen and (min-width: 1000px) {
  .content-wrapper {
    background-color: #eceef3;
    padding: 36px 45px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom: 1px #c4c4c4 solid;
  }
}
