.customer-refresh {
  padding: 0;
  height: unset;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.new-task-info {
  position: relative;
  background: #ffffff;
  border-bottom: 1px solid #c4c4c4;
  border-radius: 10px;
  padding: 12px 17px 0 17px;
  color: #3d5170;
}

.new-task-info--active {
  background-color: #d0fafe;
  padding: 12px 17px;
}

.new-task-info-complete {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #069aa9;
}

.new-task-dates {
  display: flex;
  gap: 12px;
  height: 129px;
}

.new-task-dates div {
  width: 100%;
}

.task-type span {
  background: rgba(237, 239, 245, 0.8);
  border-radius: 25px;
  font-size: 12px;
  padding: 3px 6px;
  color: #069aa9;
}

.task-type h2 {
  font-weight: 500;
  font-size: 16px;
  opacity: 0.5;
  padding-bottom: 16px;
}

.new-task-note textarea {
  width: 100%;
  height: 110px;
  border: none;
  border-bottom: 1px solid #ced3dc;
  border-radius: 10px;
  background-color: white;
  padding: 10px 15px;
  font-size: 16px;
}

.task__customer-name {
  font-style: normal;
  color: #3d5170;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */
  letter-spacing: 0.15px;
}

.task-type__value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: 52px;
  border-bottom: 1px solid #c4c4c4;
  cursor: pointer;
}

.task-type__value svg {
  opacity: 0.2;
  color: #11a8b8;
}

.task-type__value--active svg {
  opacity: 1;
}
