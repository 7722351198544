.card {
  display: flex;
  justify-content: space-between;
  height: 52px;
  gap: 16px;
  flex-direction: row;
  align-items: center;
  padding: 0px 14px;
  border-bottom: 1px solid #ced3dc;
  background-color: white;
}

.card--dark {
  background-color: #d0fafe;
}

.card__user-info__name {
  color: var(--pestix-grau-05, #3d5170);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin-top: auto;
  margin-bottom: auto;
}

.card__button-wrapper {
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.item--readonly {
  cursor: default; /* Prevents the text cursor from appearing */
  pointer-events: none; /* Prevents the element from being focusable */
}
