.service__count {
  display: flex;
  align-items: center;
  gap: 4px;
}

.service__count__number {
  height: 18px;
  width: fit-content;
  font-size: 14px;
  font-weight: 500;
}

.service__count__number--red {
  color: #cb3844;
}

.service__count__number--orange {
  color: #f29100;
}

.service__count__number--green {
  color: #0eaa00;
}

.service__count__number--gray {
  color: #8493ab;
}

.service__count__arrow {
  margin-left: 6px;
  color: #8493ab;
}
