.content-wrapper {
  padding-right: 14px;
  padding-left: 14px;
  padding-top: 18px;
}

.title {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 120% */
  letter-spacing: 0.04px;
  color: #3d5170;
}

@media only screen and (min-width: 1000px) {
  .title {
    margin-left: 24px;
    margin-bottom: 17px;
  }

  .content-wrapper {
    background-color: #eceef3;
    padding: 36px 45px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom: 1px #c4c4c4 solid;
  }
}
