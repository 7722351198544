.images-container-entire {
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  text-align: center;
}

.images-container {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
  background-color: white;
  text-align: center;
}

.loading-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 10;
}

.relative-container {
  position: relative;
}
