.workers-list {
  height: 100%;
  background-color: #d0fafe;
  overflow: hidden;
}

.workers-list__header {
  width: 100%;
  padding: 16px 16px 10px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.workers-list__header--search {
  display: flex;
  height: 40px;
  align-items: center;
  background: #ffffff;
  border-radius: 50px;
  flex-grow: 1;
  padding: 8px 16px;
}

.workers-list__header--search input {
  width: 100%;
  border: none;
  outline: none;
  background: transparent;
  font-size: 16px;
  color: #3d5170;
}

.workers-list__header--search input::placeholder {
  font-family: "Roboto";
  font-style: italic;
  opacity: 0.2;
}

.workers-list__header--search button {
  outline: none;
  border: none;
  background-color: transparent;
}

.workers-list__header--close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  background-color: #11a8b8;
  outline: none;
  border: none;
  border-radius: 28px;
  color: white;
}

.workers-list__header--close svg {
  width: 18px;
  height: 18px;
}

.workers-list__container {
  width: 100%;
  height: 95%;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  padding: 12px;
}
