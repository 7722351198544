.content-wrapper {
  width: 100%;
  padding: 12px;
  margin-top: 8px;
}

.title {
  color: #3d5170;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 122.222% */
  letter-spacing: 0.15px;
}

.reason-list {
  width: 100%;
  height: auto;
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: 11px;
  margin-bottom: 11px;
}
.error-message {
  color: white;
  background: #f27171;
  border-radius: 64px;
  text-align: center;
  padding: 4px 6px;
}
