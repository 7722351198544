.tasks__list {
  padding: 40px 12px 12px 12px;
}

.tasks-list__search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.tasks-list__search h3 {
  font-size: 18px;
  color: #3d5170;
}

.tasks-list__search button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 28px;
  border: none;
  outline: none;
  background: rgba(61, 81, 112, 0.17);
  border-radius: 50px;
}

.tasks-list__search button svg {
  width: 16px;
  height: 16px;
}

.customer-refresh {
  padding: 0;
  height: unset;
}
