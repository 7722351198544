.infastation-type {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  border-bottom: 1px #c4c4c4 solid;
  margin-top: 8px;
  color: #3d5170;
  padding: 14px;
}
.infastation-type__title {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.03px;
  padding-left: 7px;
}
.infastation-type__description {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.15px;
  margin-top: 17px;
  margin-bottom: 45px;
}
.infastation-type__pests {
  width: 100%;
  padding-left: 10px;
  margin-top: 14px;
}
.infastation-type__pests__item {
  padding: 16px 0;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.15px;
  box-shadow: 0px -1px 0px #ced3dc inset;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.infastation-type__pests__item--checked {
  color: #11a8b8;
}
.infastation-type__pests__item--unchecked {
  opacity: 0.2;
  color: #11a8b8;
}
.infastation-type__pests__other {
  margin-top: 18px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.15px;
}
.infastation-type__pests__other--textarea {
  font-size: 14px;
  letter-spacing: 0.15px;
  width: 100%;
  border: none;
  margin-top: 12px;
  outline: none;
  color: #3d5170;
}
.infastation-type__pests__other--textarea::placeholder {
  color: #3d5170;
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
}
.pests-list__quantity {
  display: inline-flex;
  align-items: flex-start;
  gap: 12px;
  font-size: 16px;
  font-family: Roboto;
  line-height: 24px;
  letter-spacing: 0.15px;
  width: 90px;
}

.input__quantity {
  width: 100%;
  height: 24px;
  border-radius: 4px;
  font-size: 16px;
  background: #d0fafe;
  border: 1px solid #069aa9;
  text-align: center;
}

.input__quantity::-webkit-outer-spin-button,
.input__quantity::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.span__quantity {
  width: 30px;
  text-align: center;
  cursor: pointer;
}
