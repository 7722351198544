:root {
  --pestix-light-green: #effeff;
  --pestix-green: #d0fafe;
}

.collapse__button {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  height: 43px;
  background-color: #f7f8fa;
  border: none;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 17px;
  margin-top: 10px;
}

.collapse__button--active {
  background-color: var(--pestix-light-green);
}

.collapse__button__label {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  height: 43px;
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: #3d5170;
  font-family: Roboto;
  line-height: 20.02px;
  letter-spacing: 0.15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.collapse__button__label.date {
  flex-direction: column; 
  align-items: flex-start;
}

input::placeholder {
  color: #3d5170;
  opacity: 30%;
  font-style: italic;
  font-size: 14px;
}

.collapse__list {
  width: 100%;
  background-color: #ffffff;
  border: none;
  border-radius: 0px 0px 10px 10px;
  color: #3d5170;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  padding-left: 17px;
  list-style: none;
  overflow: hidden; /* Hide the content that overflows the list */
  padding-left: 0;
  max-height: 0;
  transition: max-height 0.3s ease;
  margin-bottom: 10px;
}

.collapse__list--show {
  max-height: 1000px; /* A large enough value to accommodate the dynamic content */
}

.collapse__list--show--active {
  background-color: var(--pestix-green);
}

.collapse__list--empty {
  font-style: italic;
  opacity: 50%;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 12px;
}

.icons {
  display: flex;
  gap: 5px;
  cursor: pointer;
}
.additional-text {
  font-size: 12px;
  margin-top: 4px;
  display: block;
  font-weight: 400;
}
