.tabs {
  width: 100%;
  padding: 12px 12px 12px 0px;
  margin-top: 8px;
  margin-bottom: 10px;
}

.buttons {
  display: flex;
  overflow: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.customer-um {
  width: 100%;
  margin-top: 15px;
}

.customer-um__heading {
  display: flex;
  align-items: center;
}

.customer-um__title {
  display: flex;
  align-items: center;
  color: #3d5170;
  font-size: 16px;
  font-weight: 500;
}
