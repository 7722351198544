.badge {
  width: fit-content;
  height: 20px;
  border-radius: 25px;
  border-color: transparent;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
}

.badge--approved {
  background-color: #02bd4d;
  color: #ffffff;
  padding: 2px 6px;
}

.badge--declined {
  background-color: #f27171;
  color: #ffffff;
  padding: 2px 2px 2px 2px;
  padding: 2px 6px;
}

.badge--reported {
  background: #edeff5cc;
  color: #069aa9;
  padding: 2px 6px;
  margin-top: 5px;
}
