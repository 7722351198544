.arrow {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  color: black;
}

.tabs {
  position: fixed;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: #edeff5;
  z-index: 2;
}

.content {
  margin-top: 80px;
  padding: 22px 12px 0px 12px;
}

/* Give margin bottom to all divs except the last one */
.content div:not(:last-child) {
  margin-bottom: 8px;
}

@media only screen and (min-width: 1000px) {
  .tabs {
    position: absolute;
  }
}
